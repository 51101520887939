export const LOGO_LIST = [
    {
        src: '/assets/images/industry-partners/EOS.png',
        alt: 'EOS'
    },
    {
        src: '/assets/images/industry-partners/Renishaw.png',
        alt: 'Renishaw'
    },
    {
        src: '/assets/images/industry-partners/HP.png',
        alt: 'HP'
    },
    {
        src: '/assets/images/industry-partners/E-Plus-3D.png',
        alt: 'E-Plus-3D'
    },
    {
        src: '/assets/images/industry-partners/Labtrac.png',
        alt: 'Labtrac'
    },
    {
        src: '/assets/images/industry-partners/Trumpf.png',
        alt: 'Trumpf'
    },
    {
        src: '/assets/images/industry-partners/3DSystems.png',
        alt: '3DSystems'
    },
    {
        src: '/assets/images/industry-partners/dgshape.png',
        alt: 'DGShape'
    },
    {
        src: '/assets/images/industry-partners/Sisma.png',
        alt: 'Sisma'
    },
    {
        src: '/assets/images/industry-partners/DMGMori.png',
        alt: 'DMGMori'
    },
    {
        src: '/assets/images/industry-partners/SLMSolutions.png',
        alt: 'SLMSolutions'
    },
    {
        src: '/assets/images/industry-partners/nexa3D.png',
        alt: 'nexa3D'
    },
    {
        src: '/assets/images/industry-partners/imes-icore.png',
        alt: 'imes-icore'
    },
    {
        src: '/assets/images/industry-partners/PRODWAYS.png',
        alt: 'PRODWAYS'
    },
    {
        src: '/assets/images/industry-partners/Stratasys.png',
        alt: 'Stratasys'
    },
    {
        src: '/assets/images/industry-partners/2onelab.png',
        alt: '2onelab'
    },
    {
        src: '/assets/images/industry-partners/xact-metal-logo.png',
        alt: 'Xact Metal'
    },
    {
        src: '/assets/images/industry-partners/Axsys.png',
        alt: 'Axsys'
    },
    {
        src: '/assets/images/industry-partners/Axtra3D.png',
        alt: 'Axtra3D'
    },
    {
        src: '/assets/images/industry-partners/Profeta.svg',
        alt: 'Profeta'
    }
]